import { createGlobalStyle } from "styled-components";
import { palette, font } from "styled-theme";

import walletCardBg2 from "@iso/assets/images/walletCard/walletCardBg2.png";

const GlobalStyles = createGlobalStyle`
.monthly-details-table td:nth-child(1),
.monthly-details-table td:nth-child(3) {
  background-color: #EDEFEC;
}
.table-background{
  background-color: #EDEFEC;
}
.monthly-details-table .ant-table-thead > tr > th{
  background-color: #E6E6E6!important;
  border-bottom: 8px solid white;
}
.monthly-details-table .ant-table-tbody > tr:not(:last-child) > td {
  /* border-bottom: 1px solid #A6A6A5; */
}
.monthly-details-table .ant-table-tbody > tr:last-child > td {
  border-bottom: none !important;
}
.monthly-details-table .ant-table-tbody > tr > td:first-child,.monthly-details-table .ant-table-thead >tr> th:first-child{
  text-align: center;
}
.monthly-details-table .ant-table-tbody > tr > td{
  padding:17px 12px !important
}
.monthly-details-table{
  font-weight: 500;
  .text-center{
    text-align: center;
  }
}
.monthly-details-table2  .ant-table-thead > tr > th{
background-color: white !important;
color:white !important;
border-bottom: 0px solid #ff0000;
}
.monthly-details-table2 {
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    background-color: white !important;
  }
}
.monthly-details-table2 td:nth-child(1){
  background-color: white;
}
.custom-descriptions-inv{
  .ant-descriptions-item-label{
    padding:0px !important;
    border-right: none!important;
    background-color: none!important;
  }
  .ant-descriptions-item-content{
    padding:0px !important
  }
  .custom-descriptions-inv-header{
    padding:10px !important;
    background-color: #005AA9 !important;
    color: white;
    font-weight: bold !important;
    text-align: center;
  }
  .ant-descriptions-row{
    border-bottom: none !important;
  }
  .ant-descriptions-item-label{
    background-color: none;
  }
  .custom-descriptions-inv-light-blue{
    background-color: #B5D3FF !important;
    font-weight: 500;
    text-align: center;
    padding:3px!important
  }
  .custom-descriptions-inv-yellow{
    background-color: #FFF6C4 !important;
    font-weight: 500;
    text-align: center;
    padding:10px!important
  }
  .custom-descriptions-inv-red{
    background-color: #FAC6C8 !important;
    font-weight: 500;
    text-align: center;
    padding:10px!important
  }
  .custom-descriptions-inv-green{
    background-color: #BFE7D2 !important;
    font-weight: 500;
    text-align: center;
    padding:10px!important
  }
  .custom-descriptions-inv-empty{
    height:28px;
    background-color:#EAF2FF !important;
  }
  .custom-descriptions-inv-empty-white{
    height:30px;
    background-color:#ffffff !important;
  }
}
.print-footer {
  display: none; /* Hide by default */
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #E6E6E6;
  padding: 10px 0;
  font-size: 12px;
}
.print-footer2 {
  display: none; /* Hide by default */
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgb(50,147,206);
  background: linear-gradient(94deg, rgba(50,147,206,1) 0%, rgba(48,101,161,1) 36%, rgba(43,51,122,1) 100%);
  padding: 12px 0;
  font-size: 12px;
}
/* Print styles */
@media print {
  /* Footer appears at the bottom of each page */
  .print-footer {
    display: block;
  }
  .print-footer2 {
    display: block;
  }
  /* Set the footer to be at the bottom of every printed page */
  @page {
    margin: 0; /* Set margin to ensure content fills the page */
  }

  /* Prevent the footer from overlapping content */
  body {
    margin: 0;
    padding-bottom: 60px; /* Adjust to match the footer height */
  }

 
}
.label-month{
    padding: 12px!important;
    max-width: fit-content;
    background-color: #2D7BFF;
    clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%);
    color:white;
    font-weight: 500;
    padding-right:37px !important;
    padding-left:35px !important
}
.new-inv-mobile-logo{
  width:260px
}
.icon-background{
  background: rgb(77, 150, 199);
    background: linear-gradient(94deg, rgba(77, 150, 199, 1) 0%, rgba(47, 124, 181, 1) 66%, rgba(45, 108, 171, 1) 100%);
    width: fit-content;
    padding: 5px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 5px;
    .anticon{
      font-size: 16.5px;
      color: white;
    }
}
.privilege-card{
  .ant-card-body{
    padding:22px!important
  }
}
.inv-email{
  .ant-descriptions-item-content{
    text-transform: lowercase !important;;
  }
}
.force-logout-pop-up {
  z-index:99999 !important
}
.wa-widget-wrapper{
  z-index:1 !important
}
#wa-cta-button-powered{
  display:none
}
@media (max-width: 575px){

  .profile-form{
    .ant-form-item{
      .ant-col{
        flex:0%
      }
    }
    .ant-col-xs-8{
      max-width:auto !important
    }
   .ant-form-item-label{
    max-width:auto !important
   }
  }
}
.profile-form{
  .ant-form-item-label > label{
    height:auto
  }
  .ant-form-item-label{
    label{
      white-space:pre-line
    }
  }
  .ant-select-selection-item{
    color:rgba(0, 0, 0, 0.85);
  }
  .ant-picker-input{
    input{
      color:rgba(0, 0, 0, 0.85)
    }
  }
}
.transfer-sa{
  display:flex;
  max-width:400px;
  margin:auto;
  font-size:0.8em;
  padding-bottom:10px;
  border-bottom:1px dashed  #d6d6d6
}
.transfer-wrapper{
  padding: 18px;
  border-radius: 12px;
  background: #f2f4ffb3;
  border: 1px solid #c3d5ff;
  max-width: 410px;
  margin: auto;
  margin-bottom: 1em;
}
.transfer-from{
  width:50%;
  text-align:left
}
.transfer-to{
  width:50%;
  text-align:right
}
.transfer-amount{
  font-size:0.9em;
  max-width:400px;
  margin:auto;
  padding-top:18px;
}
.custom-slider{
  flex-flow:initial !important;
  overflow:auto;
  margin-bottom:20px
}

.custom-slider {
  scrollbar-width: auto;
  scrollbar-color: #dbdbdb #ffffff;
}

/* Chrome, Edge, and Safari */
.custom-slider::-webkit-scrollbar {
  width: 16px;
}

.custom-slider::-webkit-scrollbar-track {
  background: #F1F3F6;
  border-radius:10px;
  margin:0 5px
}

.custom-slider::-webkit-scrollbar-thumb {
  background-color: #9d9d9d;
  border-radius: 10px;
  border: 5px solid #F1F3F6;
}
.custom-collapse {
  .ant-collapse-header{
    text-align:center!important
  }
  .ant-collapse-item:nth-child(1){
    .ant-collapse-header{
      background-color:#2770e7ad
    }
  }
  .ant-collapse-item:nth-child(2){
    .ant-collapse-header{
      background-color:#2771e799
    }
  }
  .ant-collapse-item:nth-child(3){
    .ant-collapse-header{
      background-color:#2771e785
    }
  }
  .ant-collapse-item:nth-child(4){
    .ant-collapse-header{
      background-color:#2671e870
    }
  }
  .ant-collapse-item:nth-child(5){
    .ant-collapse-header{
      background-color:#2670e861
    }
  }
  .ant-collapse-item:nth-child(6){
    .ant-collapse-header{
      background-color:#2670e852
    }
  }
  .ant-collapse-item:nth-child(7){
    .ant-collapse-header{
      background-color:#2670e842
    }
  }
  .ant-collapse-item:nth-child(8){
    .ant-collapse-header{
      background-color:#2670e82e
    }
  }
  .ant-collapse-item:nth-child(9){
    .ant-collapse-header{
      background-color:#2670e817
    }
  }
  .ant-collapse-item:nth-child(10){
    .ant-collapse-header{
      background-color:#f2f6ff
    }
  }
}
.wallet-inv-logo{
  width:250px
}
.wallet-inv{
  text-align:right
}
.ant-btn-default {
  border-color:#1890ff;
  color:#1890ff
}
.member-card-padding{
  padding:30px 60px !important;
}
.sa-button-input{
  background:#6e7d8826;
  border:none;
  font-size:0.8em;
  color:#111473;
  min-width:65px;
  margin:8px;
}
.ant-spin-nested-loading > div > .ant-spin {
  position:fixed!important;
  top:0!important;
  left:0!important;
  z-index:4!important;
  display:block!important;
  width:100%!important;
  height:100%!important;
  max-height:100vh!important;    
  background: #ffffff91;
  z-index: 9999!important;
}
.sa-wrapper{
  border:1px solid #c0c0c0;
  border-radius:4px;
  width:100%;
  max-width:400px;
  margin:auto;
  margin-top:15px;
  display:flex;

}
.sa-password-input{
  border-radius:4px;
  border:none;
  padding:7px;
  width:90%;
  padding-right:10px;
}
.sa-password-input:focus-visible{
  border:none !important;
  outline:none;
}
.submenu-child{
  padding-left:0px !important
}
.time-css{
  color:#0073b7;
  border-radius:10px;
  padding:0.25em 1em;
  background-color:#E6EEF5;
  min-width: 130px;

}

.ql-editor{
  min-height:200px;
}
.ql-editor img{
  margin:15px 0px;
}

.action-select{
  .ant-select-arrow{
    color:white !important
  }
  .ant-select-selector{
    background-color:#1890ff !important;
    border-color:#1890ff !important;
    .ant-select-selection-placeholder{
      color:white !important;
    }
    .ant-select-selection-item{
      color:white !important;
    }
  }
}
.padding-responsive{
  padding:25px;
}

.ant-table-column-sorter{
  color:white !important
}
.ant-table-thead th.ant-table-column-has-sorters:hover{
  background:#a1bfe2ab !important
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover{
  background:#a1bfe2ab !important
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background:#0bb783 !important;
  color:white !important;
  border:#0bb783 !important
}
.dashboard-radio-button > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background:#6c6c6c !important;
  color:white !important;
  border:#6c6c6c !important;
  border-radius:0.5rem!important
}
.button-selected{
  background:#0bb783 !important;
  color:white !important;
  border:#0bb783 !important
}
.ant-select-dropdown{
  width: auto !important
}
.ant-select-dropdown-hidden{
  width: auto !important
}
.ant-input-number-lg input{
  height:45px;
}
.ant-input-number .ant-input-number-handler-wrap{
opacity:1
}
.ant-descriptions-item-container .ant-descriptions-item-content{
  text-transform:capitalize;
}
.ant-page-header-heading-title {
  font-size:25px !important;
}

.ant-carousel .slick-dots li button{
  height:6px !important;
  width:110% !important;
  background:black! important
}
.ant-layout{
 
}
  .ant-btn{
    border-radius: 4px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    // margin-bottom: 5px;
  }
  .banner-padding{
    padding:25px;
  }
  .padding-responsive-out{
    padding:20px 18px;
    margin:auto;
    max-width:90%;
  }
  @media (max-width: 767px) {
    .wallet-inv-logo{
      max-width:200px!important;
      padding-bottom:0px !important
    }
    .wallet-inv{

    }
    .member-card-padding{
      padding:20px !important;
    }
    .PageContent .BillingInformation .RightSideContent textarea{
      text-align:left!important
    }
    .mobile-textarea{
      text-align:left!important
    }
    .content-mobile{
      padding-bottom:25px;
    }
    .padding-responsive-out{
      max-width:100%;
    }
    .FormCss{
      padding:15px !important;
     
    }
    .banner-padding{
      padding:0px;
    }
    .padding-responsive{
      padding:15px;
    }
  }
  .max-sponsor-list-single{
    
    border-left:1px dashed #ccc;
    position:relative
  }

  .max-sponsor-list-single::before{
    content:'';
    display:block;
    position:absolute;
    left:0;
    top:50%;
    z-index:2;
    width:10px;
    height:1px;
    border-bottom:1px dashed #ccc;
  }
  // .max-sponsor-list-single::last-child:{
  //   content:'';
  //   display:block;
  //   position:absolute;
  //   left:0;
  //   top:50%;
  //   z-index:2;
  //   width:10px;
  //   height:1px;
  //   border-bottom:1px dashed #ccc;
  // }
  .dashboard-card > .ant-card-head{
    border-radius:15px;
    background:#5592DD;
    border-color:#5592DD;
    margin:0
  }
  .hoverable {
    :hover{
      cursor: pointer;
    }
  }
  .toptable{
    border:1px solid #558dd947 !important;
    border-radius:15px;
    background:white;
  }
  .tableUsername{
    font-weight:bold;
    padding-bottom:4px;
    font-size:1.1em;
    white-space:nowrap;
    width:100%;
    // overflow:hidden;
    // text-overflow:ellipsis;
    display:block;
    text-align:left
  }
  .tablePV
    font-size:0.9em;
    color:#91919f;
    max-width: 70px;
    text-align: right
  }
  .tableRanking{
    font-size:0.9em;
    color:#91919f;
    max-width: 70px;
    text-align: right
  }
  .button-selected{
    background:#0bb783 !important;
    color:white !important;
    border:#0bb783 !important
  }
  .dashboard-card-content{
    background-color:white;
    border-radius:10px;
    padding:15px;
    border:1px solid #558dd947
  }
  .dashboard-card > .ant-card-head > .ant-card-head-wrapper{
    .ant-card-head-title{
      font-weight:bold;
      color:white
    }
  }
  .dashboard-card > .ant-card-body{
    background-color:#e6eef5 !important;
    border-radius:15px !important;
    min-height:160px !important;
  }
  @media(min-width: 1200px) {
    .dashboard-card .ant-col-xl-5 { flex: 0 0 20%;}
  }
  
  // .dashboard-wallets-button{
  //   color:black;
  //   background-color:white;
  //   padding:8px 20px;
  //   border-radius:5px;
  // }

  .dashboard-card{
    border-radius:15px !important;
    background-color:#e6eef5 !important;
    border:1px solid #558dd947 !important
  }
  .membership-holder{
    display:block;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    border-radius:15px;
  }
  .ambassador{
    background-image: url('https://alpha2022.techbasemlm.com/Images/membership-card/ambassador-bg.png');
  }
  .globalDirector{
    background-image: url('https://alpha2022.techbasemlm.com/Images/membership-card/global-director-bg.png');
  }
  .director{
    background-image: url('https://alpha2022.techbasemlm.com/Images/membership-card/director-bg.png');
  }
  .risingStar{
    background-image: url('https://alpha2022.techbasemlm.com/Images/membership-card/rising-star-bg.png');
  }
  .distributor{
    background-image: url('https://alpha2022.techbasemlm.com/Images/membership-card/distributor-bg.png');
  }
  .user-profile-left{
    display:block;
    position:relative;
    width:100px;
    height:100px;
    margin: 0 auto;
    padding: 0;
    float:left;
    border-radius:50%;
  }
  .user-profile-right{
    display:block;
    width:calc(100% - 120px);
    margin: 0 0 0 20px;
    padding:0;
    float:right;
    min-height:200px;
  }
  .dashboard-username{
    font-size:1.2em;
    font-weight:bold;
    line-height:normal;
    text-transform:uppercase;
    overflow-wrap:break-word;
    width:95%;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .countdown-timer,
  .countdown-timer2,
  .countdown-timer3 {
    border: 1px solid #558dd947;
    border-radius:10px;
  }
  .countdown-timer > .ant-card,
  .countdown-timer2 > .ant-card,
  .countdown-timer3 > .ant-card{
    border-radius:10px;
  }
  .countdown-timer > .ant-card > .ant-card-head,
  .countdown-timer2 > .ant-card > .ant-card-head,
  .countdown-timer3 > .ant-card > .ant-card-head {
    background-color: #EC4067;
    border-radius:10px;
    color:white;
    font-weight:bold;
    text-align:center
  }
  .countdown-timer2 > .ant-card > .ant-card-head {
    background-color: #5592DD;
  }
  .countdown-timer3 > .ant-card > .ant-card-head {
    background-color: #ffa54a;
  }
  .customer-countdown-timer > .ant-card > .ant-card-head {
    background-color: #4d88e0 !important;
  }
  .countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.countdown-item {
    color: #111;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
}

.countdown-item span {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
  .accummulate-digit{
    font-size:1.45em;
    font-weight:bold;
    text-align:center;
    padding:0;
    margin:0 auto;
    color: #558dd9;
    clear:both;
    display:block;
    line-height:normal;
    padding-top:10px;
  }
.countdownApp {display: flex; justify-content: space-around; text-align: center; margin-bottom: 20px}
.time {font-size: 1.2em; font-weight: bold; color: darkslategrey }
.timeUnit {display: block; position: absolute; bottom: -20px; color: grey; font-size: 0.8em; width: 100%; text-align: center;left: 0;}

.countdown-timer2 .countdownApp svg path:last-child {stroke: #5592DD!important}
.countdown-timer3 .countdownApp svg path:last-child {stroke: #ffa54a!important}
.customer-countdown-timer .countdownApp svg path:last-child {stroke: #4D88E0!important}


/*-----------------------------------------------*/
  // Widget Wallet
  /*-----------------------------------------------*/
.ant-col .cardborder.cardWallet {
  border-radius: 15px;
  height: 120px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: none !important;
  box-shadow: 0px 0px 0px 0px #e2e2e2;
  margin: 5px 0;
  -webkit-transition: ease all 0.3s;
  transition: ease all 0.3s;
  background-image: url("${walletCardBg2}") !important;
}
.cardWallet .textbold {
  font-weight: bold;
}
.cardWallet .cardicon {
  width: 100%;
  max-width: 30px;
  height: auto;
  margin-left: 10%;
  margin-top: 12%;
}
.cardWallet .ant-card-meta-title {
  color: darkgrey;
  font-size: 0.9em !important;
}
.cardWallet .ant-card-meta-description {
  color: #222;
  font-size: 1.3em !important;
  font-weight: bold;
}


.has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }


  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }
  .ant-modal-wrap {
    .modalclass {
      .ant-modal-content {
        background-color: #ffffff;
        border-radius: 10px;
        p{
          color: black;
          margin-top: auto;
        }
      svg{
        color: black;
      }
        .ant-modal-footer{
          border-top: 0px;
        }
        .ant-modal-header{
          background-color: #ffffff;
          border-bottom: 0px;
          border-radius: 10px;
          .ant-modal-title{
            color: black;
            margin-top: 30px;
            font-size: 25px;
          }
        }
      }
    }
  }

  .modal-mobile-top .ant-modal-confirm-content p {
font-size: 1em!important
  }
  @media only screen and (max-width: 767px) {
.modal-mobile-top{
  top: 0;
  max-width: calc(100vw - 0px);
  margin: 0 auto!important;
  padding: 0;
}
}
/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font("primary", 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: #fff;
  }
}
.swal2-title{
  font-size:1.4em !important
}
.swal2-styled.swal2-confirm{
  padding:0.4em 1em;
  background-color:1890FF !important;
  min-width:100px;
}
.swal2-styled.swal2-cancel{
  padding:0.4em 1em;
  min-width:100px;
}
.ant-row:not(.ant-form-item) {
  ${
    "" /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
  background: ${palette("secondary", 1)};
  font-family: 'Poppins',sans-serif!important
}
html h1, html h2, html h3, html h4, html h5, html h6, html a, html p, html li, input, textarea, span, div, html, body, html a {

  font-family: 'Poppins',sans-serif!important
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}
.site-page-header{
  padding:0px 0px !important
}
.invoice-logo{
  width:20%
}
.anchor-responsive{
  position:fixed;
}
.ant-descriptions-row > td{
  padding-bottom:10px !important
}
@media only screen and (max-width: 767px) {
  .customer-redem-wallet{
    height:120px !important
  }
  .billinginformation-mobile{
    display:block
  }
  .anchor-mobile-only{
    display:none
  }
  .anchor-responsive{
    display:none;
  }
  .ant-card{
    font-size: 13px!important
  }
  .ant-card-body{
    padding:10px !important
  }
  .invoice-logo{
    width:40%
  }
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
  .site-page-header{
    padding:0px !important
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
.vertical-line{
  left:calc(50% - 1px);
  right:auto;
  width:1px;
  background:#dee2e6;
  border-width:2px;
  top:0px;
  height:calc(100% + -22px);
  position:absolute !important;
  border-right:1px solid #dee2e6 !important
}
.table-content{
padding:15px;
}
.tableHeader{
  
  padding:1.2em !important;
  font-size:1em;
  font-weight:bold;
  color:#558dd9;
  text-align:center;
  background-color:#E6EEF5;

}
.table-tab
  {
    font-weight:bold;
    color:#6c6c6c!important;
    background:none !important;
    border:none !important;
  }
  .table-tab:not(:first-child)::before{
    background:none !important
  }
  // .table-tab:not(.ant-radio-button-wrapper-disabled){
  //   color:#ffffff !important;
  //   background:#6c6c6c !important;
  //   border-radius:0.5em !important
  // }
/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

input, .input-select, .ant-form{
  font-size: 14px!important; 
}



.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
padding: 12px!important
}

.customer-table-header{
  .ant-table-thead > tr > th {
    background-color:#4d88e029 !important
  }
}
.wa-widget-wrapper{
  bottom:auto !important;
  top:93px;
  width:auto !important
}
`;

export default GlobalStyles;
