import React, { lazy, Suspense } from "react";
import { Route, Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useWindowSize from "@iso/lib/hooks/useWindowSize";
import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";
import appActions from "@iso/redux/app/actions";
import { OutsideTopbar } from "./containers/Topbar/Topbar";
import sidebarImage from "@iso/assets/images/AG_NUTRITION_LOGO-02.png";
import { Layout, Row, Col } from "antd";

import { DashboardContainer, DashboardGlobalStyles } from "./containers/Dashboard/DashboardOut.styles";
import ApiCall from "./library/helpers/ApiCall";

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
const styles = {
  layout: { flexDirection: "row", overflowX: "hidden" },
  content: {
    padding: "70px 0 0",
    flexShrink: "0",
    background: "#f1f3f6",
    position: "relative",
  },
  footer: {
    background: "#ffffff",
    textAlign: "left",
    borderTop: "1px solid #ededed",
    color: "grey",
  },
};

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

const publicRoutes = [
  {
    path: "/Maintenance",
    component: lazy(() => import("@iso/containers/Pages/ServerMaintenance/Maintenance")),
  },
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN_STAFF,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignInStaff")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import("@iso/containers/Pages/ForgotPassword/ForgotPassword")),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import("@iso/containers/Pages/ResetPassword/ResetPassword")),
  },

  {
    path: "/handleOldAGPage",
    component: lazy(() => import("@iso/containers/handleOldAGPage")),
  },
  {
    path: "/SPCS",
    component: lazy(() => import("@iso/containers/Ecommerce/Checkout/SenangPayCheckStatus")),
  },
  {
    path: "/INVOICE/:id",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/RegInvoiceListPublic")),
  },
  {
    path: "/WALLETINVOICE/:id",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/WalletInvoice")),
  },
  {
    path: "/paymentInvoice",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/RegPaymentInvoice")),
  },
  {
    path: "/monthlyBonusStatementDetails",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/MonthStatementDetails")),
  },
  {
    path: "/doInvoice/:id",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/OldDOPaymentInvoice")),
  },
  {
    path: "/deliveryOrderInvoice",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/RegDeliveryOrder")),
  },
  {
    path: "/RELOADINVOICE/:id",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/ReloadInvoice")),
  },
];

const globalMall = [
  {
    path: "/shop",
    component: lazy(() => import("@iso/containers/EcommerceOut/Shop/DesktopViewOutside")),
  },
  {
    path: "/productdetail",
    component: lazy(() => import("@iso/containers/EcommerceOut/Shop/ProductDetail")),
  },
  {
    path: "/cart",
    component: lazy(() => import("@iso/containers/EcommerceOut/Cart/Cart")),
  },
  {
    path: "/checkoutSummary",
    component: lazy(() => import("@iso/containers/EcommerceOut/Checkout/Summary")),
  },
  {
    path: "/checkout",
    component: lazy(() => import("@iso/containers/EcommerceOut/Checkout/Checkout")),
  },
  {
    path: "/paymentPage",
    component: lazy(() => import("@iso/containers/EcommerceOut/Checkout/PaymentPage")),
  },
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  const dispatch = useDispatch();
  const appHeight = useSelector((state) => state.App.height);
  const { width, height } = useWindowSize();

  React.useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {globalMall.map((route, index) => (
              <Route key={index} path={route.path + "/:id"} exact={route.exact}>
                <DashboardContainer>
                  <DashboardGlobalStyles />
                  <Layout style={{ height: height }}>
                    <OutsideTopbar />
                    <Layout style={styles.layout}>
                      <Layout
                        className="isoContentMainLayout"
                        style={{
                          height: appHeight,
                        }}
                      >
                        <Content className="isomorphicContent" style={styles.content}>
                          <div>
                            <route.component />
                          </div>
                        </Content>
                        <Footer style={styles.footer}>
                          <div style={{ padding: "40px 40px" }}>
                            <Row gutter={[16, 16]}>
                              <Col lg={10} md={10} sm={10} xs={24}>
                                <Row style={{ display: "block" }}>
                                  <img src={sidebarImage} alt="ag logo" width="60%" />
                                </Row>
                                <Row style={{ width: "75%", fontSize: "0.95em" }}>
                                  <span>
                                    We at AG Nutrition provide outstanding products & services that meet our distributor
                                    and consumer’s needs. We deliver premium value!
                                  </span>
                                </Row>
                              </Col>
                              <Col lg={7} md={7} sm={7} xs={24}>
                                <Row
                                  style={{
                                    display: "block",
                                    fontSize: "1.3em",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <span>LATEST POSTS</span>
                                </Row>
                                <Row style={{ width: "75%", fontSize: "0.95em" }}>
                                  <a
                                    href="https://www.agnutritioninternational.com/component/content/article/38-blog/health-topic/77-promotional-features-black-elderberry-extract-inhibits-the-replication-of-sars-cov2-in-vitro.html?Itemid=101"
                                    itemprop="url"
                                    target="_blank"
                                  >
                                    Black Elderberry extract inhibits the replication of SARS-CoV2 in vitro
                                  </a>
                                </Row>
                                <Row>
                                  <small>21 April 2022</small>
                                </Row>
                                <br />
                                <Row style={{ width: "75%", fontSize: "0.95em" }}>
                                  <a
                                    href="https://www.agnutritioninternational.com/component/content/article/38-blog/health-topic/76-the-benefits-of-witch-hazel-for-your-skin-including-how-it-can-help-tighten-pores-and-reduce-acne.html?Itemid=101"
                                    itemprop="url"
                                    target="_blank"
                                  >
                                    The benefits of witch hazel for your skin, including how it can help tighten pores
                                    and reduce acne
                                  </a>
                                </Row>
                                <Row>
                                  <small>29 August 2021</small>
                                </Row>
                              </Col>
                              <Col lg={7} md={7} sm={7} xs={24}>
                                <Row
                                  style={{
                                    display: "block",
                                    fontSize: "1.3em",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <span>HEAD OFFICE</span>
                                </Row>
                                <Row style={{ width: "75%", fontSize: "0.95em" }}>
                                  <span>
                                    No. G-9 Infinity Tower, <br />
                                    Jalan SS 6/3, 47301 <br />
                                    Petaling Jaya, Malaysia. <br />
                                    Contact: +603-7802 8381
                                  </span>
                                </Row>
                                <br />
                                <Row style={{ width: "75%", fontSize: "0.95em" }}>
                                  <a href="mailto: info@agnutritioninternational.com">
                                    <p class="sppb-addon-title">info@agnutritioninternational.com</p>
                                  </a>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Footer>
                        <Footer style={{ background: "#f1f1f1" }}>
                          <div style={{ padding: "0px 40px" }}>
                            <Row gutter={[16, 16]}>
                              <Col lg={10} md={10} sm={10} xs={24}>
                                <Row style={{ width: "75%", fontSize: "0.95em" }}>
                                  <span class="sp-copyright">
                                    © 2023 AG Nutrition International Sdn. Bhd.
                                    <div>
                                      <a
                                        href="https://agnutritioninternational.oss-ap-southeast-3.aliyuncs.com/AG%20Nutrition%20Code%20of%20Conduct%20FV%20Oct24%20101024.pdf"
                                        target="_blank"
                                      >
                                        Code Of Conduct
                                      </a>{" "}
                                      |{" "}
                                      <a href="https://www.agnutritioninternational.com/terms" target="_blank">
                                        Terms &amp; Conditions
                                      </a>{" "}
                                      |{" "}
                                      <a href="https://www.agnutritioninternational.com/privacy-notice" target="_blank">
                                        Privacy Notice
                                      </a>
                                    </div>
                                  </span>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Footer>
                      </Layout>
                    </Layout>
                  </Layout>
                </DashboardContainer>
              </Route>
            ))}
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
