import React, { useState, useRef, useContext, useEffect } from "react";

import { Map } from "immutable";
import moment from "moment";
import axios from "axios";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Form, DatePicker, Input, InputNumber, Select } from "antd";
import Swal from "sweetalert2";
import ApiCall from "./ApiCall";
const { Option } = Select;
const TextArea = Input;

const CryptoJS = require("crypto-js");

const key = "!z$C&F)J@NcRfUjX";

export function reverseString(str) {
  return str.split("").reverse().join("");
}

export function generateSignatureFrom(rawString) {
  const crypto = require("crypto");
  return reverseString(crypto.createHash("sha256").update(rawString).digest("hex")).toUpperCase();
}

export function aesEncryptServer(text) {
  var iv = CryptoJS.enc.Utf8.parse("dsddsdsdsdsdsdss");

  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).ciphertext.toString();

  return encrypted;
}

// Alert Messages

export function AlertSuccessMessage(title) {
  return new Promise(async function (resolve, reject) {
    Swal.fire({
      icon: "success",
      title: title,
    });
  });
}

export function AlertFailedMessage(title) {
  return new Promise(async function (resolve, reject) {
    Swal.fire({
      icon: "error",
      title: title,
    });
  });
}

export function aesDecryptServer(text) {
  var iv = CryptoJS.enc.Utf8.parse("dsddsdsdsdsdsdss");
  var textMadonna = CryptoJS.enc.Hex.parse(text);
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: textMadonna,
    },
    CryptoJS.enc.Utf8.parse(key),
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  const plainText = decrypted.toString(CryptoJS.enc.Utf8);
  return plainText;
}

export function aesEncrypt(text) {
  var encrypted = CryptoJS.AES.encrypt(text, key);
  return encrypted.toString();
}

export function aesDecrypt(text) {
  var decrypted = CryptoJS.AES.decrypt(text, key);
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function getRegisterCountry() {
  return new Promise(async function (resolve, reject) {
    axios({
      method: "GET",
      url: process.env.REACT_APP_API_KEY + "/Listing/GetRegisterCountryList",
    }).then((response) => {
      resolve(response.data.result);
    });
  });
}

export function calculateWeight(weightItemList) {
  return new Promise(async function (resolve, reject) {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/delivery/GetProductWeight",
      data: {
        weightItemList,
      },
    }).then((response) => {
      resolve(response.data.message);
    });
  });
}

export function calculateWeightUpgrade(memberID, weightItemList) {
  return new Promise(async function (resolve, reject) {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_KEY + "/delivery/GetProductWeightUpgradeSales",
      data: {
        memberID,
        weightItemList,
      },
    }).then((response) => {
      resolve(response.data.message);
    });
  });
}

export const exportToExcel = (json, fileName, title = "AG Nutrition", defaultHeader) => {
  // run when have record
  if (json.length > 0) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    json.map((e) => {
      for (var key of Object.keys(e)) {
        e[key] = {
          v: e[key],
          t: "s",
          s: {
            border: {
              top: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
              bottom: { style: "thin" },
            },
          },
        };
      }
    });

    var header = [];

    // Use to generate header name for excel
    if (defaultHeader == undefined) {
      for (var key of Object.keys(json[0])) {
        // Set first letter to upper case
        var string = key[0].toUpperCase() + key.slice(1);

        // Split header by capital letter if not string like ID and PV
        if (string.match(/(?=[A-Z]{3,})/)) {
          header.push(string.split(!string.match(/(?=[A-Z]{3,})/) ? /(?=[A-Z])/ : /(?=[A-Z]{3,})/).join(" "));
        } else header.push(string.split(!string.match(/(?=[A-Z]{2,})/) ? /(?=[A-Z])/ : /(?=[A-Z]{2,})/).join(" "));
      }
    } else {
      header = defaultHeader;
    }

    const ws = XLSX.utils.json_to_sheet(json);

    // Border style for all header
    var headerStyle = [];
    header.map((e) => {
      headerStyle.push({
        v: e,
        t: "s",
        s: {
          font: { bold: true },
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
          },
        },
      });
    });

    XLSX.utils.sheet_add_aoa(ws, [headerStyle]);

    // Build the column width
    ws["!cols"] = Object.keys(json[0]).map((v) => {
      var maxx = json.reduce((w, r) => Math.max(w, r[v]?.length ?? 0), 10);
      return { wch: maxx + 2 };
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
};

export function AGPtoMYR(value) {
  const data = sessionStorage.getItem("countrySelected") == "127" ? 4.0 : 5.0;
  return value * data * 1.02;
}

export function getProfileData() {
  const decrypt = aesDecrypt(localStorage.getItem("profileData"));
  const profileData = JSON.parse(decrypt);
  return profileData;
}

export function getAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
}

export async function logoutToken() {
  const memID = getProfileData()?.memberID;
  if (!!memID) {
    await ApiCall.post(
      "/token/LogoutAccessToken",
      JSON.stringify({
        username: memID,
      })
    );
  }
  clearToken();
}

export function clearToken() {
  localStorage.removeItem("profileData");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("memberID");
  sessionStorage.removeItem("lastActiveTime");
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("accessToken");
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function thousandSeparator(x, minimumFractionDigits = 2, maximumFractionDigits = 2) {
  return parseFloat(x).toLocaleString("en-US", { minimumFractionDigits, maximumFractionDigits });
}

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

// Editable Table Modules, currently supported string, number, date, select, textarea

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
export function CheckNewUsername(MemberID) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/IndoSystem/GetValidIndoMemberInfo",
      JSON.stringify({
        MemberID: MemberID,
      }),
      { dontLoad: true }
    )
      .then((response) => {
        if (response.data.message === "SUCCESS") resolve(response.data.result);
        else resolve(null);
      })
      .catch((error) => resolve(null));
  });
}
export function CheckUsername(Username) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/basic/CheckUsername",
      JSON.stringify({
        value: Username,
      }),
      { dontLoad: true }
    )
      .then((response) => {
        if (response.data.code === "401") resolve(true);
        // Username exists
        else resolve(false); // Username does not exist
      })
      .catch((error) => resolve(false));
  });
}
export function CheckNewIndoUsername(UsernameIndo) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/IndoSystem/CheckIndoNewUsername",
      JSON.stringify({
        value: UsernameIndo,
      }),
      { dontLoad: true }
    )
      .then((response) => {
        if (response.data.code === "200") resolve(!response.data.result);
        else resolve(null);
      })
      .catch((error) => resolve(null));
  });
}
export function GetMemberRegisterCountry(Username) {
  return new Promise(async function (resolve, reject) {
    ApiCall.post(
      "/member/GetMemberRegisterCountry",
      JSON.stringify({
        value: Username,
      }),
      { dontLoad: true }
    )
      .then((response) => {
        if (response.data.code == "200") resolve(response.data.result);
        else resolve(false);
      })
      .catch((error) => resolve(false));
  });
}
export const EditableCell = ({
  title,
  tableName,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType,
  selectList,
  ...restProps
}) => {
  const dateFormat = "YYYY-MM-DD";
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (inputType === "date") {
      form.setFieldsValue({
        [dataIndex]: moment(record[dataIndex], "YYYY-MM-DD"),
      });
    } else {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, tableName);
    } catch (errInfo) {
      // console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode =
      editing && inputType === "string" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : editing && inputType === "number" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <InputNumber
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            //precision={2}
          />
        </Form.Item>
      ) : editing && inputType === "date" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <DatePicker ref={inputRef} onBlur={save} format={dateFormat} />
        </Form.Item>
      ) : editing && inputType === "select" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Select ref={inputRef} onSelect={save}>
            {selectList.map((item) => (
              <Option value={item.parameterValue}>{item.parameterValue}</Option>
            ))}
          </Select>
        </Form.Item>
      ) : editing && inputType === "textArea" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <TextArea ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};
